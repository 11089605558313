$(document).ready(() => {
    $("#main").onepage_scroll({
        sectionContainer: "section", // sectionContainer accepts any kind of selector in case you don't want to use section
        easing: "ease", // Easing options accepts the CSS3 easing animation such "ease", "linear", "ease-in",
        // "ease-out", "ease-in-out", or even cubic bezier value such as "cubic-bezier(0.175, 0.885, 0.420, 1.310)"
        animationTime: 1000, // AnimationTime let you define how long each section takes to animate
        pagination: true, // You can either show or hide the pagination. Toggle true for show, false for hide.
        updateURL: false, // Toggle this true if you want the URL to be updated automatically when the user scroll to each page.
        beforeMove: function(index) {}, // This option accepts a callback function. The function will be called before the page moves.
        afterMove: function(index) {}, // This option accepts a callback function. The function will be called after the page moves.
        loop: false, // You can have the page loop back to the top/bottom when the user navigates at up/down on the first/last page.
        keyboard: true, // You can activate the keyboard controls
        responsiveFallback: false, // You can fallback to normal page scroll by defining the width of the browser in which
        // you want the responsive fallback to be triggered. For example, set this to 600 and whenever
        // the browser's width is less than 600, the fallback will kick in.
        direction: "vertical" // You can now define the direction of the One Page Scroll animation. Options available are "vertical" and "horizontal". The default value is "vertical".  
    });

    function pathPrepare($el) {
        var lineLength = $el[0].getTotalLength();
        console.log(lineLength);
        $el.css("stroke-dasharray", lineLength);
        $el.css("stroke-dashoffset", lineLength);
    }

    var $line1 = $("line#line1");
    var $line2 = $("line#line2");
    var $circle = $("path#circle");

    // prepare SVG
    pathPrepare($line1);
    pathPrepare($line2);
    pathPrepare($circle);

    const controller = new ScrollMagic.Controller();

    // scene1
    const tween = new TimelineMax()
        .to("#section1 hr", 2, { width: "100%" })
        .to("#section1 .by", 1, { opacity: 1 });
    const scene = new ScrollMagic.Scene({
            triggerElement: "#section1",
        })
        .setTween(tween)
        .addTo(controller);

    // background image
    // $('.bcg-image').each(function() {
    //     new ScrollMagic.Scene({
    //             triggerElement: $(this).parent(),
    //             duration: '100%'
    //         })
    //         .setTween(
    //             new TimelineMax()
    //             .to(this, 4, { y: '-30%', ease: Linear.easeNone })
    //             // .from(this, 4, { y: '30%', ease: Linear.easeNone })
    //         )
    //         .addTo(controller);
    // })

    // scene description
    const tweenDesc = new TimelineMax()
        .to("#section-description .description", .5, { opacity: 1 })
        .to("#section-description .slogan", .5, { opacity: 1 });
    new ScrollMagic.Scene({
            triggerElement: "#section-description",
        })
        .setTween(tweenDesc)
        .addTo(controller);

    // scene5
    const tween5 = new TimelineMax()
        .to($line1, 1, { strokeDashoffset: 0, ease: Linear.easeNone })
        .to($line2, 1, { strokeDashoffset: 0, ease: Linear.easeNone })
        .to($circle, 1, { strokeDashoffset: 0, ease: Linear.easeNone })
        .to("#section-contact .title", 1, { opacity: 1 })
        .to("#section-contact hr", 1, { marginLeft: "28vw" })
        .to("#section-contact .address", 1, { opacity: 1 });
    new ScrollMagic.Scene({
            triggerElement: "#section-contact",
        })
        .setTween(tween5)
        .addTo(controller);





});